import { template as template_fbe9c057debf4a1aa97f2b97db45384e } from "@ember/template-compiler";
const SidebarSectionMessage = template_fbe9c057debf4a1aa97f2b97db45384e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
