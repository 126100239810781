import { template as template_5da3365713bc4eacb732aaa18dd2da11 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5da3365713bc4eacb732aaa18dd2da11(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
